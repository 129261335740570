import styled from "styled-components";

export const Div = styled.div`
    display: flex;
    margin-bottom: 38px;
    width: 100%;
    > button {
        margin-left: 12px;
        padding: 8px 12px;
        background-color: #FFF;
        border-radius: 5px;
    }
    > input {
        padding: 8px 12px;
        background-color: #FFF;
        width: 90%;
        border-radius: 5px;
    }
`