import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import Web3 from 'web3'
import AppView from './AppView'

const { REACT_APP_END_POINT, REACT_APP_CHAIN_ID, REACT_APP_TOKEN_NAME } = process.env
/* Useful debugging kitty: 12309 472305 121989 410598 */
/* Issue: 312631 5420 363053 */

const web3 = new Web3(Web3.givenProvider)

const App = () => {
    const [loggedIn, setLoggedIn] = useState(undefined)
    const [token, setToken] = useState(Cookies.get(REACT_APP_TOKEN_NAME) || undefined)
    const [user, setUser] = useState({
        valid: false,
        followers: [],
        following: [],
        balance: 0,
        birthed: 0,
        balanceAll: 0,
        address: undefined
    })

    const handleLogout = useCallback(() => {
        Cookies.remove(REACT_APP_TOKEN_NAME)
        setLoggedIn(undefined)
        setToken(undefined);
        setUser({
            valid: false,
            followers: [],
            following: [],
            balance: 0,
            birthed: 0,
            balanceAll: 0,
            address: undefined
        })
    }, [setLoggedIn, setToken])

    useEffect(() => {
        const handleAccountsChanged = (accounts) => {
            if (accounts.length === 0 && loggedIn) {
                handleLogout()
            }
        }

        const handleDisconnect = (error) => {
            console.error('Metamask error:', error)
            handleLogout()
        }

        const handleReload = () => window.location.reload()

        if (token && loggedIn && window.ethereum) {
            window.ethereum.on('accountsChanged', handleAccountsChanged)
            window.ethereum.on('disconnect', handleDisconnect)
            window.ethereum.on('chainChanged', handleReload)

            return () => {
                window.ethereum.off('accountsChanged', handleAccountsChanged)
                window.ethereum.off('disconnect', handleDisconnect)
                window.ethereum.off('chainChanged', handleReload)
            }
        }
  }, [token, loggedIn, handleLogout])

    const handleSignIn = async () => {
        if (window.ethereum) {
            const chainId = await window.ethereum.request({ method: 'eth_chainId' })
            if (chainId === REACT_APP_CHAIN_ID) {
                try {
                    await window.ethereum.request({ method: 'eth_requestAccounts' })
                    const web3 = new Web3(window.ethereum)
                    const message = 'Sign this message to authenticate'
                    const accounts = await web3.eth.getAccounts()
                    const signature = await web3.eth.personal.sign(message, accounts[0], '')
                    const response = await axios.post(`${REACT_APP_END_POINT}/kittyfamily-auth`, { address: accounts[0], signature, message })
                    Cookies.set(REACT_APP_TOKEN_NAME, response.data.token)
                    setLoggedIn(response.data.address)
                    setToken(response.data.token)
                    setUser(response.data)
                } catch (error) {
                    console.error('Error:', error)
                }
            } else {
                alert('you are on the wrong chain!')
            }
        }
    }

    const handleSignOut = async () => {
        if (token) {
            try {
                await axios.post(`${REACT_APP_END_POINT}/kittyfamily-/logout`, { token })
            } catch (error) {
                console.error('Logout error:', error)
            }
            handleLogout()
        }
    }

    const checkToken = useCallback(async () => {
        try {
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (chainId === REACT_APP_CHAIN_ID) {
                const { data } = await axios.post(`${REACT_APP_END_POINT}/kittyfamily-auth/check-token`, { token });
                if (data.valid) {
                    setLoggedIn(data.address.toLowerCase())
                    setUser(data)
                } else {
                    handleLogout()
                } 
            }
        } catch (error) {
            handleLogout()
        }
    }, [token, setLoggedIn, handleLogout])

    useEffect(() => {
        const checkMetamask = async () => {
            try {
                if (window.ethereum) {
                    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                    if (accounts.length === 0) {
                        // Metamask is closed or accounts are not accessible
                        handleLogout()
                    } else {
                        // Metamask is open and accounts are accessible, proceed with token check
                        checkToken()
                    }
                } else {
                    // Metamask is not installed
                    handleLogout()
                }
            } catch (error) {
                console.error('Metamask error:', error);
                handleLogout()
            }
        }
    
        if (token) {
            checkMetamask()
        }
    }, [token, checkToken, handleLogout])

  return (
    <AppView {...{ loggedIn, handleSignIn, handleSignOut, web3, user, token, checkToken }} />
  )
}

export default App
