import React from 'react'
import { Link } from 'react-router-dom'
import * as Styled from './Logo.style'
import logo from 'svg/logo.svg'

const Logo = () => {
  return (
    <Styled.Div larger={(window.location.pathname === '/')}>
      <img src={logo} alt={'Kitty.Family'} />
      <h1><Link to="/">Kitty.Family</Link></h1>
      <h2>by <Link to={{ pathname: "https://kitty.international" }} target="_blank">Kitty.International</Link></h2>
    </Styled.Div>
  )
}

export default Logo
