import React from 'react'
import * as Styled from './Connect.style'
import { ReactComponent as Metamask } from 'svg/metamask.svg'

const Connect = ({ loggedIn, handleSignIn, handleSignOut }) =>
  <Styled.Div>
    {loggedIn
      ? <button onClick={handleSignOut}><Metamask />{'SIGN OUT'}</button>
      : <button onClick={handleSignIn}><Metamask />{'SIGN IN'}</button>
    }
  </Styled.Div>

export default Connect
