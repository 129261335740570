

export const breaks = {
	xs: '320px',
	sm: '480px',
	ms: '540px',
	md: '760px',
	lg: '960px',
	xlg: '1080px',
	xl: '1200px',
	xxl: '1360px',
	xxxl: '2160px'
}

export const gutters = {
	0: '0rem',
	2: '0.2rem',
	3: '0.3rem',
	4: '0.4rem',
	5: '0.5rem',
	8: '0.8rem',
	10: '1rem',
	12: '1.2rem',
	14: '1.4rem',
	15: '1.5rem',
	16: '1.6rem',
	20: '2rem',
	24: '2.4rem',
	30: '3rem',
	32: '3.2rem',
	42: '4.2rem',
	45: '4.5rem',
	56: '5.6rem',
	64: '6.4rem',
	85: '8.5rem',
	120: '12rem',
	xxs: '0.4rem',
	xs: '0.8rem',
	sm: '1.5rem',
	md: '2.4rem',
	lg: '3.2rem',
	xlg: '4.8rem',
	xl: '6.4rem',
	xxl: '8.5rem',
	xxxl: '12rem'
}

export const fontSize = {
	xxxs: '0.8rem',
	xxs: '1rem',
	xs: '1.2rem',
	sm: '1.4rem',
	md: '1.6rem',
	ml: '1.8rem',
	lg: '2rem',
	xl: '2.4rem',
	xxl: '2.8rem'
}

export const headingSize = {
	xxs: '3.2rem',
	xs: '3.7rem',
	sm: '4.2rem',
	md: '5.0rem',
	lg: '6.0rem',
	xl: '7.2rem',
	xxl: '8.0rem'
}

export const grey = {
	0: '#ffffff',
	50: '#fcfbfa',
	100: '#f9f8f6',
	200: '#f3f1ee',
	300: '#e7e6e4',
	400: '#c4c3c0',
	500: '#9c9c9b',
	600: '#82817d',
	700: '#575553',
	900: '#2a2825',
	1000: '#000000'
}

export const modalZIndex = 100000

export const colors = {
	primary: '#ff9b6a',
	secondary: '#E96BD4',
	red: '#f39db4',
	redAlt: '#ef526d',
	green: '#49B749',
	warmGrey: '#82817d',
	pink: '#e27dd1',

	black: '#0b0b0b',
	autumnmoon: '#ffe8bb',
	babypuke: '#bcba5e',
	bridesmaid: '#ffc2df',
	bubblegum: '#ef52d1',
	chestnut: '#a56429',
	coralsunrise: '#ff9088',
	cyan: '#45f0f4',
	dahlia: '#b8bdff',
	doridnudibranch: '#fa9fff',
	downbythebay: '#83b293',
	eclipse: '#484c5b',
	isotope: '#e4ff73',
	forgetmenot: '#4eb4f9',
	gold: '#fcdf35',
	limegreen: '#aef72f',
	mintgreen: '#43edac',
	oasis: '#ccffef',
	olive: '#729100',
	palejade: '#c3d8cf',
	parakeet: '#49b749',
	pinefresh: '#177a25',
	pumpkin: '#ffa039',
	sapphire: '#4c7aef',
	sizzurp: '#7c40ff',
	strawberry: '#ef4b62',
	thundergrey: '#828282',
	topaz: '#0ba09c',
	twilightsparkle: '#ba8aff',

	darkBubblegum: '#c23da8'
}

export const colorsAlternate = {
	autumnmoon: '#fdf3e0',
	babypuke: '#eff1e0',
	bridesmaid: '#ffd5e5',
	bubblegum: '#fadff4',
	chestnut: '#efe1da',
	coralsunrise: '#fde9e4',
	cyan: '#c5eefa',
	dahlia: '#e6eafd',
	doridnudibranch: '#faeefa',
	downbythebay: '#cde5d1',
	eclipse: '#e5e7ef',
	isotope: '#effdca',
	forgetmenot: '#dcebfc',
	gold: '#faf4cf',
	limegreen: '#d9f5cb',
	mintgreen: '#cdf5d4',
	oasis: '#e6faf3',
	olive: '#ecf4e0',
	palejade: '#e7f1ed',
	parakeet: '#e5f3e2',
	pinefresh: '#dbf0d0',
	pumpkin: '#fae1ca',
	sapphire: '#d3e8ff',
	sizzurp: '#dfdffa',
	strawberry: '#ffe0e5',
	thundergrey: '#eee9e8',
	topaz: '#d1eeeb',
	twilightsparkle: '#ede2f5'
}

export const colorsAlternate2 = {
	autumnmoon: '#e7d4b4',
	babypuke: '#cfd4b0',
	bridesmaid: '#eba3bc',
	bubblegum: '#eebce3',
	chestnut: '#d4beb3',
	coralsunrise: '#e7c3bb',
	cyan: '#83cbe0',
	dahlia: '#bec5e7',
	doridnudibranch: '#e1cce1',
	downbythebay: '#97bc9c',
	eclipse: '#cdd1e0',
	isotope: '#cde793',
	forgetmenot: '#a7caea',
	gold: '#e3daa1',
	limegreen: '#b4d9a2',
	mintgreen: '#9ad7a5',
	oasis: '#bee1d4',
	olive: '#c8d6b4',
	palejade: '#c0d1ca',
	parakeet: '#bcd4b8',
	pinefresh: '#adcf9b',
	pumpkin: '#efc8a4',
	sapphire: '#a2c2eb',
	sizzurp: '#c1c1ea',
	strawberry: '#efbaba',
	thundergrey: '#dbccc7',
	topaz: '#a8d5d1',
	twilightsparkle: '#dcc7ec'
}

export const colorsAlternate3 = {
	bubblegum: '#f9a7ea',
	gold: '#fff095',
	limegreen: '#c8ee85',
	sapphire: '#a6bdf7',
	violet: '#ddc4ff'
}

export const colorsAlternate4 = {
	bubblegum: '#fdf0fa',
	gold: '#fdfae6',
	limegreen: '#e9fae1',
	sapphire: '#e9f4ff',
	violet: '#f6f1fa'
}

/* LEGACY */
export const EYE_COLORS = {
	autumnmoon: '#fdf3e0',
	babypuke: '#eff1e0',
	bridesmaid: '#ffd5e5',
	bubblegum: '#fadff4',
	chestnut: '#efe1da',
	coralsunrise: '#fde9e4',
	cyan: '#c5eefa',
	dahlia: '#e6eafd',
	doridnudibranch: '#faeefa',
	downbythebay: '#cde5d1',
	eclipse: '#e5e7ef',
	isotope: '#effdca',
	forgetmenot: '#dcebfc',
	gold: '#faf4cf',
	limegreen: '#d9f5cb',
	mintgreen: '#cdf5d4',
	oasis: '#e6faf3',
	olive: '#ecf4e0',
	palejade: '#e7f1ed',
	parakeet: '#e5f3e2',
	pinefresh: '#dbf0d0',
	pumpkin: '#fae1ca',
	sapphire: '#d3e8ff',
	sizzurp: '#dfdffa',
	strawberry: '#ffe0e5',
	thundergrey: '#eee9e8',
	topaz: '#d1eeeb',
	twilightsparkle: '#ede2f5'
}
