import chat from 'svg/chat.svg'
import { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import * as Styled from './Menu.style'
import { ReactComponent as Mystery } from 'svg/mystery.svg'
import { ReactComponent as Hat } from 'svg/hat.svg'
import { ReactComponent as Bag } from 'svg/market.svg'

const Menu = ({ handleClick, total, loggedIn, catsWithHats/* , socket */ }) => {
  // const [report, setReport] = useState(undefined)

  // useEffect(() => {
  //     if (socket) {
  //         socket.on("progress", (report) => {
  //           setReport(report)
  //         })
  //     }
  // },[socket])
  const history = useHistory()
  const getRandomNumber = max => Math.floor(Math.random() * max) + 1
  return (
    <Styled.Div>
      <div onClick={handleClick}>
        <img src={chat} alt={'Kitty Chatrooms'} />
      </div>
      <div>
        <Mystery onClick={() => total ? history.push(`/kitty/${getRandomNumber(total)}`) : history.push(`/kitty/${getRandomNumber(2023514)}`)} />
      </div>
      <div><Link to={'/kitty-hats'}><img src={'/images/kitty-hats/logo.png'} alt={''} /></Link></div>
      {/* <div>
        <Hat onClick={() => catsWithHats.length && history.push(`/kitty/${catsWithHats[getRandomNumber(catsWithHats.length - 1)]}`)} />
      </div>
        */}
      <div><Link to={'/search'}><Bag /></Link></div>
      {/* <div><Link to={'/report'}>{'Report'}</Link>{report && `[${parseFloat((100 / report.Total) * report.All).toFixed(3)}%]`}</div> */}
    </Styled.Div>
  )
}



export default Menu
