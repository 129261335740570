import React, { Component } from 'react'
import PropTypes from 'prop-types'
import mojs from 'mo-js/build/mo.min.js'
import Portal from 'components/Portal'
import * as Styled from './Confetti.style'

class Confetti extends Component {
  componentDidMount() {
    const lineBurst = new mojs.Burst({
      parent: this.confetti,
      count: 8,
      radius: { 20: 600 },
      angle: 0,
      children: {
        shape: 'line',
        radius: { 0: 40 },
        stroke: '#EF52D1',
        strokeWidth: 5,
        strokeDasharray: '100%',
        strokeDashoffset: { '-100%': '100%' },
        duration: 2000,
        scale: 1,
        easing: 'quad.out',
        isForce3d: true,
      },
    });

    const squareBurst = new mojs.Burst({
      parent: this.confetti,
      count: 8,
      angle: 22.5,
      radius: { 20: 600 },
      children: {
        shape: 'rect',
        radius: 10,
        radiusX: 10,
        scale: 1,
        angle: { 0: 720 },
        fill: 'none',
        stroke: '#AEF72F',
        strokeWidth: { 10: 0 },
        delay: 150,
        duration: 2000,
        easing: 'quad.out',
        isForce3d: true,
      },
    });

    const zigzagBurst = new mojs.Burst({
      parent: this.confetti,
      count: 8,
      radius: { 20: 450 },
      angle: 0,
      children: {
        shape: 'zigzag',
        points: 7,
        radius: 30,
        radiusY: 10,
        fill: 'none',
        stroke: '#FCDF35',
        strokeWidth: { 5: 2 },
        strokeLinecap: 'round',
        scale: 1,
        strokeDasharray: '100%',
        strokeDashoffset: { '-100%': '100%' },
        duration: 2000,
        delay: 300,
        easing: 'quad.out',
        isForce3d: true,
      },
    });

    const cirleBurst = new mojs.Burst({
      parent: this.confetti,
      count: 8,
      radius: { 20: 300 },
      angle: 22.5,
      children: {
        shape: 'circle',
        radius: 10,
        fill: 'none',
        stroke: '#45F0F4',
        strokeWidth: { 2: 20 },
        delay: 450,
        duration: 1000,
        easing: 'quad.out',
        isForce3d: true,
      },
    });

    this.timeline = new mojs.Timeline();
    this.timeline.add(lineBurst, squareBurst, zigzagBurst, cirleBurst);
    this.meow = new Audio('/audios/like-button/LikeButton_15_Genesis.mp3');

    this.play();
  }

  shouldComponentUpdate() {
    this.play();

    return false;
  }

  play = () => {
    setTimeout(() => {
      // avoid playing again
      if (this.played) {
        return;
      }
      this.played = true;
      this.timeline.play();
      try {
        this.meow.currentTime = 0;
        !this.props.isMute && this.meow.play();
      } catch (error) {
        // do nothing
      }
    }, 600);
  };

  render() {
    const { usePortal } = this.props;

    const confetti = (
      <Styled.Div ref={(confetti) => { this.confetti = confetti; }} />
    );

    return usePortal ? <Portal>{confetti}</Portal> : confetti;
  }
}

Confetti.propTypes = {
  usePortal: PropTypes.bool,
  isMute: PropTypes.bool,
};

Confetti.defaultProps = {
  usePortal: false,
  isMute: false,
};

export default Confetti;
